<template lang="pug">
div(v-if='Navegador')
    .d-none.d-md-block
      //- BANNER SUPERIOR
      v-row.navegadorsuperior#navegador
        
          v-col(cols=12 md=2).justify-center.align-center.ml-7
            router-link(:to='{name: "inicio"}')
              v-img(contain :src='Navegador.logo' flat height='70px' ).elevation-0
          //- titulo
          v-col(cols=12 md=1).mt-3
              p(v-html='Pie_de_pagina.horario.titulo' style="color: #00AA7F").text-center
          
            
          v-col(cols=12 md=2 style=" margin-left: 27%; position: absolute; z-index: 1").mt-6
            //- Lunes-viernes
            p(v-html='Pie_de_pagina.horario.lunes_viernes' ).white--text.rutas_nav.text-ruta
            p(v-html='Pie_de_pagina.horario.horaLV').white--text.rutas_nav.text-ruta
        
          v-col(cols=12 md=2 style=" margin-left: 40%; position: absolute; z-index: 1").mt-6
            //- sabado
            p(v-html='Pie_de_pagina.horario.sabado'  ).white--text.rutas_nav.text-ruta
            p(v-html='Pie_de_pagina.horario.horaS' ).white--text.rutas_nav.text-ruta
            
          v-col(cols=12 md=1 style=" margin-left: 52%; position: absolute; z-index: 1").justify-start.align-center.mt-4 
            p( style="color: #00AA7F;  font-size: 16px").text-center.font-italic.font-weight-bold  Contacto
            
          v-col(cols=12 md=2 style=" margin-left: 60%; position: absolute; z-index: 1").mt-6  

              p(v-html="Pie_de_pagina.correo"  ).white--text.font-italic.font-weight-light.rutas_nav.text-ruta
              p(v-html="Pie_de_pagina.numero"   ).white--text.font-italic.rutas_nav.rutas_nav.text-ruta
              

          v-col(cols=12 md=3 style=" margin-left: 85%; position: absolute; z-index: 1").mt-6
          
              i(v-for='Navegador, i of Navegador.redes_sociales' :key='`Red${i}`'  style="font-size: 20px; cursor: pointer; color: white"
                :class='Navegador.icon' @click='redirigir_a(Navegador.enlace)').mr-3.ml-3.justify-center.align-center
            
           
      //- NAVEGADOR PC
      v-app-bar(  color='#490383'  height='60'  app).elevation-0.navegador
          .fila-elementos.align-items-center.justify-center
              //- router-link(:to='{name: "inicio"}')
              //-     v-img(contain :src='Navegador.logo' flat height=65 ).ml-1.elevation-0
              .columna-rutas.align-center.justify-center
                  .ruta(v-for='{texto, ruta}, i in Navegador.menu' :key='i').align-center.justify-center
                      button(:seleccionado='ruta=== ruta_actual' @click='dirigir_a(ruta)').align-center.justify-center 
                          span(v-html='texto' style="font-size: 15px").white--text.text-center.align-center.justify-center
              //- v-col.lineamedia     
              //-   v-row
              //-     v-img( :src='Navegador.imagen_whatsapp' height='40px' max-width='40' contain).mr-3.ml-3.mt-1
              //-     h1(v-html="Navegador.numero" style="font-size: 25px").white--text.font-italic.justify-center.mt-1
    
    //- NAVEGADOR MOVIL
    .d-md-none
      v-row.navegadorsuperior.justify-end.align-end.pa-2
        v-col(cols=5)
            i(v-for='Navegador, i of Navegador.redes_sociales' :key='`Red${i}`'  style="font-size: 20px; cursor: pointer; color: white"
              :class='Navegador.icon' @click='redirigir_a(Navegador.enlace)').mr-3.ml-2
      //- bar
      v-app-bar(    v-if='Navegador'  color='#490383'  height='100' ).pr-5
        router-link(:to='{name: "inicio"}')
          v-img(contain :src='Navegador.logo'  height=60 width=110).elevation-0
        v-spacer
        v-btn(icon  @click='drawer = !drawer' dark)
          v-icon(color='white' large) fa-bars
      //- drawer
      v-navigation-drawer( temporary v-model="drawer" color='#490383'  app).justify-center.align-center
        v-list
          v-list-item.justify-center.align-center
            router-link(:to='{name: "inicio"}').justify-center.align-center
              v-img( height=85 width='100%' :src='Navegador.logo' contain ).justify-center.align-center
            v-list-item-content
          .fila-elementos.align-items-center.justify-center
                  
            .columna-rutas-movil
              .ruta(v-for='{texto, ruta}, i in Navegador.menu' :key='i' ).my-3.ruta
                v-list-item-content
                  button(:seleccionado='ruta=== ruta_actual' @click='ruta_actual!=ruta ? dirigir_a(ruta) : drawer=false') 
                    v-list-item-title(v-html='texto').white--text
        v-container(style="margin-top: 10%")
          v-img(:src='Citas.calendario'  @click='redirigir_a(Citas.enlace)' contain height="70px" )
          p(v-html="Citas.agenda" style="line-height: 0.3em" ).white--text.text-center.mt-3
      
        
</template>
<script>
  export default {
    data: ()=>({
      drawer: false,
    }),
    computed: {
      ruta_actual(){
        return this.$route.name
      },
    },
    methods: {
            redirigir_a(enlace){
                window.open(enlace, '_blank')
            },
        },
  }
</script>
<style lang="sass" scoped>

.rutas_nav
  line-height: 0.4em
.text-ruta
  font-size: 12px
 

.navegadorsuperior
  background-color: #30015E
  padding: 0em

.navegador
  position: sticky
.fila-elementos
  display: flex
  flex-direction: row
  .columna-rutas
    display: flex
    flex-direction: row
    margin-left: 26em
    z-index: 1
    position: relative
    .ruta
      
      button
        
        margin-right: 30px
        height: 35px
        border-radius: 15px!important
        &[seleccionado='true']
          border: 3px solid #00AA7F !important
        span  
          padding: 0.8em
          
          

.columna-rutas-movil
  flex-direction: row
  .ruta
      button
        width: 125px
        height: 35px
        border-radius: 15px!important
        &[seleccionado='true']
          border: 3px solid #00AA7F !important
        span
.lineamedia
  margin-top: 5px
  border-radius: 30px 0 0 30px
  height:50px
  width: 500px
  margin-left: 100px
  margin-bottom: 2px
  background-color: #30015E !important
        
</style>